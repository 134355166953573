<template>
    <section>
        <modal ref="modalCrearDireccion" :titulo="`${editando ? 'Actualizar' : 'Crear'} dirección`" tamano="modal-lg" icon="ubicación" no-aceptar adicional="Guardar" @adicional="handle()" @cancelar="volver()" @cerrar="volver()">
            <div class="row mx-0 position-relative">
                <i class="icon-left text-general f-15 position-absolute cr-pointer" style="top:30px;left:2px;z-index:5;" @click="volver" />
                <div class="col-auto" />
                <ValidationObserver ref="form" class="col">
                    <div class="row mx-0 justify-center">
                        <div class="col-10">
                            <p class="text-general f-14 pl-3">Dirección</p>
                            <el-select
                            v-model="model.direccion"
                            filterable
                            remote
                            class="w-100"
                            reserve-keyword
                            :remote-method="remoteMethod"
                            @change="getDetails()"
                            >
                                <el-option
                                v-for="item in options"
                                :key="item.place_id"
                                :label="item.description"
                                :value="item.description"
                                />
                            </el-select>
                        </div>
                        <div class="col-5 my-1 mt-4">
                            <ValidationProvider v-slot="{errors}" rules="max:8" name="código postal" vid="codigo_postal">
                                <p class="text-general f-14 pl-3">Código postal (Opcional)</p>
                                <el-input v-model="model.codigo_postal" placeholder="Ej: 123456" maxlength="8" show-word-limit class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-5 my-1 mt-4">
                            <ValidationProvider v-slot="{errors}" rules="max:40" name="complemento" vid="direccion_anexo">
                                <p class="text-general f-14 pl-3">Piso, apartamento o local</p>
                                <el-input v-model="model.direccion_anexo" placeholder="Ej: Torre A, Apartamento 201" maxlength="40" show-word-limit class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-5 my-1">
                            <ValidationProvider v-slot="{errors}" rules="max:30" vid="barrio" name="barrio / colonia">
                                <p class="text-general f-14 pl-3">Barrio / Colonia (Opcional)</p>
                                <el-input v-model="model.barrio" maxlength="30" show-word-limit class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-5 my-1">
                            <ValidationProvider v-slot="{errors}" rules="required|max:25" vid="nombre" name="guardar como">
                                <p class="text-general f-14 pl-3">Guardar como</p>
                                <el-input v-model="model.nombre" placeholder="Ej: Casa" maxlength="25" show-word-limit class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 mt-3">
                            <p class="text-general pl-3 f-14">Ubicación en el mapa*</p>
                            <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                                Haga click aquí, para seleccionar la ubicación de su tienda
                            </p>
                            <mapa
                            ref="mapaPeq"
                            :coordenadas="coordenadas"
                            :buscar="model.direccion"
                            icono="cedis-point-map.svg"
                            @actualizar="actualizarCoordenadas"
                            />
                        <!-- <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                            Haga click aquí, para seleccionar la ubicación de su tienda
                        </p> -->
                        </div>
                    </div>
                </ValidationObserver>
                <div class="col-auto" />
            </div>
        </modal>
        <mapa-google-modal
        ref="modalMapaGoogle"
        busqueda
        postal
        :buscar="model.direccion"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        @cancelar="cancelarMapa()"
        />
        <modal-no-direccion ref="modalNoDireccion" @change="onChangeModal" @onClose="onClose()" />
    </section>
</template>

<script>
import localizacion from '~/services/localizacion';
import direcciones from '~/services/tiendas/direcciones';
export default {
    data(){
        return{
            options: [],
            coordenadas: {
                lat: 7.080734,
                lng: -73.147827,
            },
            model: {
                direccion: '',
                codigo_postal: '',
                nombre: '',
                direccion_anexo: '',
                barrio: '',
                latitud: 0,
                longitud: 0,
            },
            editando: false,
            id: null
        }
    },
    computed: {
        user(){
            return this.$store.getters['auth/obtenerUsuario']
        }
    },
    methods: {
        toggle(){
            this.limpiar()
            this.$refs.modalCrearDireccion.toggle();
        },
        async editar(direccion){
            this.limpiar()
            let model = _.cloneDeep(direccion)
            this.editando = true
            this.id = direccion.id
            await this.remoteMethod(model.direccion)
            this.model.direccion = model.direccion
            this.model.barrio = model.barrio
            this.model.codigo_postal = model.codigo_postal
            this.model.nombre = model.nombre
            this.model.direccion_anexo = model.direccion_anexo
            this.model.latitud = model.latitud
            this.model.longitud = model.longitud
            this.coordenadas = {
                lat: model.latitud,
                lng: model.longitud,
            }
            this.$refs.mapaPeq.setPosition()

            this.$refs.modalCrearDireccion.toggle();
        },
        async remoteMethod(query){
            try {
                let googleMaps = await this.$iniciarGoogleMaps();
                this.options = await localizacion.getPlaces(googleMaps, query);
                this.options.push({place_id: 'no_direccion', description: 'No aparece mi dirección'})
            } catch (e){
                this.options = [];
                this.options.push({place_id: 'no_direccion', description: 'No aparece mi dirección'})
            }
        },
        async getDetails(){
            let { place_id = '' } = this.options.find(el => el.description == this.model.direccion) ?? {}

            if(!place_id) return


            if(place_id == 'no_direccion'){
                this.onClose()
                return
            }

            let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();
            let geocoder = new googleMaps.Geocoder();

            geocoder.geocode({
                'placeId': place_id
            },
            async(responses, status) => {
                if (status == 'OK'  ){
                    let lat = responses[0].geometry.location.lat();
                    let lng = responses[0].geometry.location.lng();
                    this.coordenadas = {lat, lng}
                    this.$refs.mapaPeq.setPosition()
                    this.model.latitud = lat
                    this.model.longitud = lng

                    const obj = await localizacion.getLocationFromLatLng(googleMaps, lat, lng);
                    this.model.codigo_postal = +obj.postalCode
                    this.model.city = obj.city
                    this.model.state = obj.state
                    this.model.country = obj.country
                }
            });
        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        abrirModalMapa(){
            this.$refs.modalCrearDireccion.toggle();
            this.$refs.modalMapaGoogle.toggle()
        },
        async handle(){
            if(this.editando) await this.actualizar()
            else await this.guardar()
        },
        async guardar(){
            try {
                this.model.direction = this.model.direccion
                this.model.id_user = this.user.id
                const { data } = await direcciones.crear(this.model)
                if (data.message == 'Exito'){
                    this.notificacion('', 'Dirección creada correctamente', 'success')
                    this.$emit('volver')
                    this.$emit('actualizar')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async actualizar(){
            try {
                this.model.direction = this.model.direccion
                this.model.id_user = this.user.id
                this.model.id = this.id
                const { data } = await direcciones.actualizar(this.model)
                if (data.message == 'Exito'){
                    this.notificacion('', 'Dirección actualizada correctamente', 'success')

                    this.$emit('volver')
                    this.$emit('actualizar')
                }

            } catch (error){
                this.error_catch(error)
            }
        },
        volver(){
            this.$emit('volver')
        },
        limpiar(){
            this.model = {
                direccion: '',
                codigo_postal: '',
                nombre: '',
                barrio: '',
                direccion_anexo: '',
                latitud: 0,
                longitud: 0,
            }
            this.editando = false
            this.id = null
            this.$refs.form.reset()
        },
        onClose(){
            this.$refs.modalCrearDireccion.toggle();
            this.$refs.modalNoDireccion.toggle();
        },
        async onChangeModal(direccion){
            this.onClose()
            this.model.direccion = direccion
            await this.remoteMethod(direccion)
            await this.getDetails()
        },
        async actualizarCoordenadasCiudad({ latitud, longitud }){
            this.abrirModalMapa()
            this.model.latitud = latitud
            this.model.longitud = longitud
            let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();

            const obj = await localizacion.getLocationFromLatLng(googleMaps, latitud, longitud);
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.model.codigo_postal = +obj.postalCode
            this.model.direccion = obj.direction
            this.model.city = obj.city
            this.model.state = obj.state
            this.model.country = obj.country
            this.$refs.mapaPeq.setPosition()
            this.options = []
        },
        cancelarMapa(){
            this.$refs.modalCrearDireccion.toggle();
        }
    }
}
</script>
